import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import React from "react";
import NavRoute from "./containers/routes/NavRoutes";
import NavBar from "./components/navbar/navbar";

function App() {
  return (
    <div className="App">
      <NavRoute />
    </div>
  );
}

export default App;
