import { createSlice } from "@reduxjs/toolkit";
import { pipeline } from "../../utilities/constants/constants";

const appSlicer = createSlice({
  name: "app",
  initialState: {
    pipelineData: pipeline,
    data_storage: [],
    dataConnectivity: {},
    username: "abc",
    jwtIdToken: "",
    reset: {},
    edit: false,
  },
  reducers: {
    updateSource: (state, action) => {
      state.pipelineData.source = action.payload;
    },
    updateProcessing: (state, action) => {
      state.pipelineData.processing = action.payload;
    },
    updateDestination: (state, action) => {
      state.pipelineData.destination = action.payload.destination;
      state.pipelineData.schedule = action.payload.schedule;
      state.pipelineData.notifications = action.payload.notifications;
      state.pipelineData.triggers = action.payload.triggers;
    },
    setDataConnectivity: (state, action) => {
      state.dataConnectivity = action.payload;
    },
    updateName: (state, action) => {
      state.pipelineData.name = action.payload;
    },
    updateDataStorage: (state, action) => {
      state.data_storage = action.payload;
    },
    updateUserName: (state, action) => {
      state.username = action.payload;
    },
    updateToken: (state, action) => {
      state.jwtIdToken = action.payload;
    },
    updatePipeline: (state, action) => {
      state.pipelineValue = action.payload;
    },
    updateReset: (state, action) => {
      state = {};
    },
    updateEdit: (state, action) => {
      state.edit = action.payload;
    },
  },
});

export const {
  updateProcessing,
  updateSource,
  updateDestination,
  setDataConnectivity,
  updateDataStorage,
  updateUserName,
  updateToken,
  updatePipeline,
  updateReset,
  updateEdit,
} = appSlicer.actions;

export default appSlicer.reducer;
