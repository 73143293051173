import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './main.css';

function Main({ children }) {
    return (
        <div
            className="main"
            style={{
                height: '100vh',
                // paddingLeft: '150px',
                // maxHeight: 'auto',
                paddingTop: '6rem',
                overflow: 'scroll',
                // position: 'fixed'
            }}
        >
            <Container>
                <Row>
                    <Col>{children}</Col>
                </Row>
            </Container>
        </div>
    );
}

export default Main;
