import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import AzureLogin from "../auth/AzureLogin";
import AuthenticateRoute from "./AuthenticateRoute";
import RedirectIfAuthenticatedRoute from "./RedirectIfAuthenticatedRoute";
import { RouteKeys } from "./route-keys";
import NavBar from "../../components/navbar/navbar";

const AuthPage = lazy(() => import("../auth"));
const NotFound = lazy(() => import("../notfound"));

/* Components*/
const Pipeline = lazy(() => import("../../components/pipeline/pipeline"));
const Source = lazy(() => import("../../components/source/source"));
const ProcessComponent = lazy(() =>
  import("../../components/process/processing-component")
);
const DestinationComponent = lazy(() =>
  import("../../components/destination/destination-component")
);
const User = lazy(() => import("../user/User"));
const CreateUser = lazy(() => import("../../components/Crud/CreateUser"));
const EditUser = lazy(() => import("../../components/Crud/EditUser"));

/**
 * NavRoute component is responsible for defining routing for the application
 *
 * @returns {JSX.Element} returns JSX element for routing
 */
export default function NavRoute() {
  return (
    
    <>
      <Suspense fallback={"Loading..."}>
        <Routes>
          <>
            <Route
              path={RouteKeys.Home}
              element={
                <AuthenticateRoute>
                  <Pipeline />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.Source}
              element={
                <AuthenticateRoute>
                  <Source />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.Processing}
              element={
                <AuthenticateRoute>
                  <ProcessComponent />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.Destination}
              element={
                <AuthenticateRoute>
                  <DestinationComponent />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.User}
              element={
                <AuthenticateRoute>
                  <User />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.CreateUser}
              element={
                <AuthenticateRoute>
                  <CreateUser />
                </AuthenticateRoute>
              }
            />
             <Route
                            path={RouteKeys.EditUser}
                            element={
                                <AuthenticateRoute>
                                    <EditUser />
                                </AuthenticateRoute>
                            }
                        />
            <Route
              path={RouteKeys.Auth}
              element={
                <RedirectIfAuthenticatedRoute>
                  <AuthPage />
                </RedirectIfAuthenticatedRoute>
              }
            />
            <Route
              path={RouteKeys.AzureLogin}
              element={
                <RedirectIfAuthenticatedRoute>
                  <AzureLogin />
                </RedirectIfAuthenticatedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </>
        </Routes>
      </Suspense>
    </>
  );
}
