import { AuthService, AzureGraphService, AzureService } from "./services";
import { RoleService } from "./services/role";
import {CrudService} from "./services/crud";
import {UserService} from "./services/user";
export default class ApiClient {
  crud;
  user;
  /**
   * An object containing the API domain and the currently set token.
   *
   * @type {Object}
   */
  store = {
    apiDomain: "",
    token: undefined,
  };

  /**
   * Creates a new instance of NeogeneApiClient.
   *
   * @param {string} apiDomain - The base URL of the Neogene API.
   */
  constructor(apiDomain) {
    this.store.apiDomain = apiDomain;
    this.auth = new AuthService(this.store);
    this.azureGraphApi = new AzureGraphService();
    this.azure = new AzureService(this.store);
    this.crud = new CrudService(this.store);
    this.user = new UserService(this.store);
    this.rolelist = new RoleService(this.store);
  }

  /**
   * Sets the token for authenticating API requests.
   *
   * @param {string} token - The API token to set.
   */
  setToken(token) {
    this.store.token = token;
  }
}
