import React from 'react';
import NavBar from '../navbar/navbar';
import { Row, Col } from 'react-bootstrap';
import Main from '../main/main';

export default function Layout({ children }) {
   

    return (
        <>
            <>
                <NavBar></NavBar>
                <Row>
                    <Col>
                        <Main>{children}</Main>
                    </Col>
                </Row>
            </>
        </>
    );
}
