import { toastr } from 'react-redux-toastr';
import * as http from './http';
import ApiService from './service';

export class RoleService extends ApiService {
    async getRole() {
        const url = `${this.apiDomain}/roles`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
            console.log(response, "response");
        return { data: response };
        
    }
}
