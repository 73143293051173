// import './loader.scss'
import { Spinner } from "react-bootstrap";

function Loader() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Spinner variant="primary" />
    </div>
  );
}
export default Loader;

export function DestinationPageLoader() {
  return (
    <div
      style={{
        position: "absolute",
        height: "20vh",
        width: "20vw",
        display: "flex",
        marginLeft: "60vh",
        marginTop: "10vh",
        justifyContent: "center",
        alignItems: "center",
        background: "grey",
        opacity: "0.5",
        zIndex: 99,
      }}
    >
      <Spinner variant="primary" />
    </div>
  );
}
