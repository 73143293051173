export const pipeline = {
	"name": "",
	"source": {},
	"processing": {
		"columns": [],
		"validation": [],
		"conversion": [],
		"loadtype": ""
	},
	"destination": [
		{
			"connector": "",
			"details": {}
		}
	],
	"schedule": {
		"frequency": "",
		"ineterval": "",
		"from": "",
		"until": ""
	},
	"notifications": {
		"mail": {},
		"teams": {
			"channel": "",
			"webhook": ""
		}
	}
};

export const regexData = {
	"all": {},
	"alphabets-only":
	{
		regex: /^[A-Za-z ]+$/,
		message: "Alphabet only allowed"
	},
	"numeric-only":
	{
		regex: /^[0-9]*$/,
		message: "Numbers only allowed"
	}
};