import React from "react";
import { Navigate } from "react-router-dom";
import api from "../../api";
import { useAuth } from "../../hooks/useAuth";
import { RouteKeys } from "./route-keys";

/**
 * Redirects the user to the dashboard if they are authenticated, or renders the provided children if they are not authenticated.
 *
 * @param {object} props - The component props.
 * @param {React.ReactElement} props.children - The content to be rendered if the user is not authenticated.
 *
 * @returns {React.ReactElement} The rendered JSX element.
 */
const RedirectIfAuthenticatedRoute = (props) => {
  const auth = useAuth(api.auth);

  if (!auth.isAuthenticated) {
    return <div className="flex">{props.children}</div>;
  } else {
    return (
      <>
        <Navigate
          to={window.localStorage.getItem("redirect") ?? RouteKeys.Home}
          state={{ path: window.location.pathname }}
        />
      </>
    );
  }
};

// const Layout = () => {
//   const children = React.useMemo(() => props.children, [props.children]) //eslint-disable-line no-undef
//   return <div>{children}</div>
// }

export default RedirectIfAuthenticatedRoute;
// export default withErrorBoundary(
//   RedirectIfAuthenticatedRoute,
//   Layout,
//   async (error, info) => {
//     const obj = {
//       Error: `${error}`,
//       Context: `${JSON.stringify(info)}`,
//       URL: `${window.location.href}`,
//     }
//     api.loggerService.addLogger(obj)
//   }
// )
