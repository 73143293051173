import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const authSlicer = createSlice({
  name: "authUser",
  initialState: {
    redirectTo: "/",
  },
  reducers: {
    loggedInAction: (state, action) => {
      state.token = action.payload.token;
      state.profile = action.payload.user;
    },
    profileUpdatedAction: (state, action) => {
      state.profile = action.payload;
    },
    redirectSaveAction: (state, action) => {
      state.redirectTo = action.payload;
    },
    logoutAction: (state, action) => {
      api.setToken(undefined);
      state.token = undefined;
      state.profile = undefined;
    },
    redirectApplyAction: (state, action) => {
      state.redirectTo = undefined;
    },
    changeRole: (state, action) => {
      state.profile.Role.RoleName = action.payload;
    },
  },
});

export const {
  loggedInAction,
  profileUpdatedAction,
  redirectSaveAction,
  redirectApplyAction,
  changeRole,
  logoutAction,
} = authSlicer.actions;

export default authSlicer.reducer;
