import { configureStore } from "@reduxjs/toolkit";
import AppReducer from "./slicers/AppSlicer";
import AuthSlicer from "./slicers/authSlicer";

import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

const reducers = combineReducers({
  app: AppReducer,
  authUser: AuthSlicer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authUser"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
