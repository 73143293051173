import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../navbar/navbar.scss";
import { MdNotifications } from "react-icons/md";
import { HiUser } from "react-icons/hi";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { AzureLogout } from "../../containers/auth/AzureLogin";
import { RouteKeys } from "../../containers/routes/route-keys";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function NavBar() {
  const state = useSelector((selector) => selector.authUser);
  let { display_name: username } = state?.profile;

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="navbar-header navbar-expand-lg custom-navbar " >  
        <Container>  
          <Navbar.Brand className="app-heading">DataOPS</Navbar.Brand>  
          <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{backgroundColor: "whitesmoke", padding:'2px'}}/>  
          <Navbar.Collapse id="responsive-navbar-nav">  
            <Nav className="me-auto">  
              <Nav.Link><Link className="links" to={RouteKeys.Home}> Pipeline</Link></Nav.Link>  
              {/* <Nav.Link><span className="navbar-links">Configuration</span></Nav.Link>   */}
              <NavDropdown  title= {<span style={{color: "whitesmoke"}}>Admin</span>} id="basic-nav-dropdown" >     
                <NavDropdown.Item><Link className="drop_links" to={RouteKeys.User}>User</Link></NavDropdown.Item>
              </NavDropdown>  
            </Nav>  
            <Nav>  
              {/* <Nav.Link><MdNotifications size={"1.3rem"} style={{ color: "white" }} /></Nav.Link>   */}
              <Nav.Link >  
              <HiUser size={"1.3rem"} style={{ color: "white" }} />
              <span className="navbar-links">Hi {username}</span>
              </Nav.Link>  
            </Nav>  
            <Nav.Link>
              <AzureLogout />
            </Nav.Link>
          </Navbar.Collapse>  
        </Container>  
      </Navbar> 
      {/* <Navbar expand="lg" className="navbar-menu">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <NavLink className="links" to={RouteKeys.Home}>
                  Pipeline
                </NavLink>
              </Nav.Link>
              <Nav.Link>
                <NavLink className="links" to={RouteKeys.User}>
                  Users
                </NavLink>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
    </>
  );
}
