export const RouteKeys = {
  Home: "/",
  Auth: "/auth",
  AzureLogin: "/auth/aad-redirect",
  Source: "/source",
  User: "/user",
  Processing: "/processing",
  Destination: "/destination",
  CreateUser: "/createUser",
  CreateUser: '/addUser',
  EditUser: '/edituser/:id'
};
